import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Building Memories, Enhancing Lives, Shaping Futures</h6>
			            <h1 className="section-title">Two Decades of Crafting Dreams and Communities</h1>
			            <p>With nearly two decades of dedicated service since our inception, we've been crafting spaces that become the canvas for cherished memories. Our creations are not merely residences, but the heart of communities, where families grow, bonds strengthen, and futures prosper. We're committed to enhancing lives and enabling thriving businesses, because for us, real estate is more than structures; it's the foundation of aspirations.</p>
			          </div>
			          <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
			            <li>
			              <a href={publicUrl+"assets/img/img-slide/11.jpg"} data-rel="lightcase:myCollection">
			                <img src={publicUrl+"assets/img/img-slide/11.jpg"} alt="Image" />
			              </a>
			            </li>
			            <li>
			              <a href={publicUrl+"assets/img/img-slide/12.jpg"} data-rel="lightcase:myCollection">
			                <img src={publicUrl+"assets/img/img-slide/12.jpg"} alt="Image" />
			              </a>
			            </li>
			            <li>
			              <a href={publicUrl+"assets/img/img-slide/13.jpg"} data-rel="lightcase:myCollection">
			                <img src={publicUrl+"assets/img/img-slide/13.jpg"} alt="Image" />
			              </a>
			            </li>
			          </ul>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-right">
			          <img src={publicUrl+"assets/img/others/9.png"} alt="About Us Image" />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV2