import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonials</h6>
			          <h1 className="section-title">Clients Feedback</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						"I recently purchased a property through Deyaar Developers, and I'm absolutely thrilled with my new home. The attention to detail and quality of construction are top-notch. Kudos to Deyaar for making my dream a reality!"</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ali Khan</h5>
			                <label>Software Engineer</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						As a healthcare professional, finding a tranquil home was crucial for me. Deyaar Developers understood my needs and helped me find the perfect place. I now have a sanctuary to come back to after a long day at the hospital.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			              
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ayesha Ahmed</h5>
			                <label>Medical Professional:</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Finding a comfortable place near my university was essential. Deyaar Developers made the process easy, and I'm now happily settled in my new apartment. It's become my study haven.Thank you Deyaar Developers.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5> Hassan Ali</h5>
			                <label>University Student</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						As an interior designer, I have an eye for detail. Deyaar's properties truly impressed me with their quality finishes and thoughtful design. It's a pleasure working with a company that values excellence</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			       
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5> Fatima Malik</h5>
			                <label>Interior Designer</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial