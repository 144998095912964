import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ProductSliderV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
	
			<div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Deyaar Developers's</h6>
			          <h1 className="section-title"> Ongoing Projects</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
				  {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/newlahorecity.png"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> New Lahore City, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 18,000<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">New Lahore City</Link></h2>
			            <div className="product-description">
			              <p>New Lahore City is a contemporary residential community in Lahore designed to enhance every facet of life and elevate the quality of living. Your aspirations for an ideal and opulent lifestyle, while ensuring excellent value for your investment, will find fulfillment in this community. Every detail has been meticulously considered in the planning and development of this society to create an extraordinary living environment.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>
				  {/* ltn__product-item */}
<div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/prime.png"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Bahria Prime, Behria Town, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 45,000<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Bahria Prime </Link></h2>
			            <div className="product-description">
			              <p>Bahria Prime Lahore, a premium apartment project by Asaan Home & Athar Associates, is strategically located in Tipu Block, Bahria Town Lahore. The luxury building offers 1, 2 & 3-bed apartments and 4-bed Sky Villas, providing over 30 amenities. Developed by successful real estate developers, Bahria Prime ensures a secure investment opportunity in a fully automated, modern, and amenity-rich environment.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>
{/* ltn__product-item */}

<div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/skyline.png"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Al Kabir Town Phase-II</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 38,334<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">The Skyline Mall & Residences</Link></h2>
			            <div className="product-description">
			              <p>
Skyline Mall & Residences, crafted by Brothers Developers, A towering symbol, standing over 120 feet, it redefines urban living with cutting-edge construction. Positioned on Raiwind Road, it provides seamless access to Lake City and Bahria Town, ensuring comfort and convenience. The strategic location makes it ideal for commuters and residents, promising an upscale lifestyle.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>
{/* ltn__product-item */}
									<div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/grand10.jpg"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Grand X, Behria Town, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 140,400<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Grand X</Link></h2>
			            <div className="product-description">
			              <p>Grand Height X, a collaboration by LandMark and Deyaar Developers, introduces a vibrant Bahria Town Lahore community. High-end apartments integrate with diverse amenities, offering budget-friendly choices. Bahria Lahore's charm, with facilities and infrastructure, naturally appeals to residents. The growing population drives demand for commercial spaces, ensuring addressal of community needs.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>

{/* ltn__product-item */}
<div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/grand11.jpg"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Grand XI, Behria Town, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 99,200<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Grand XI</Link></h2>
			            <div className="product-description">
			              <p>Grand Heights 11, a pinnacle in Bahria Town Lahore, offers affordable commercial shops and apartments. Developed by Landmarks Developers, this 4-kanal project in Midway Commercial Bahria Town provides a life-changing investment with its prime location, varied property sizes, and ongoing development. Whether for resale or rental income, these shops and apartments stand out as wise choices in Bahria Town.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>
				  

				  {/* ltn__product-item */}
					
					<div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/midway.png"} alt="#" /></Link>
	
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> 79- Midway Commercial, Behria Town, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			             
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as RS 23,200<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">79- Midway Commercial</Link></h2>
			            <div className="product-description">
			              <p>In collaboration with Deyaar Developers, delve into a remarkable opportunity at 79 Midway Commercial in Bahria Town. Offering a reasonable installment plan, this prime location boasts views of Bahria Town Lahore's largest park. For comprehensive details on this ideal investment, connect with Future Consultant Real Estate & Builders. Seize the chance to invest wisely in 79 Midway Commercial, a prestigious project.</p>
			            </div>
			           
			          </div>
			        
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/zaitoon.png"} alt="#" /></Link>
			           
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Zaitoon City, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			            
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as Rs 17,900<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Zaitoon City</Link></h2>
			            <div className="product-description">
			              <p>ZAITOON's narrative aligns with Lahore's evolution into a global epicenter for culture, hospitality, and lifestyle. In 2021, the New York Times recognized Lahore as one of the premier tourist destinations worldwide.

Boasting nearly three and a half decades of expertise in real estate development, ZAITOON has been a trailblazer in introducing high-rise hybrid structures, delivering breathtaking projects. </p>
			            </div>
			         
			          </div>
			  
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/lifestyle.png"} alt="#" /></Link>
			           
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Zaitoon Lifestyle, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			           
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as Rs 15,999<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Zaitoon Lifestyle</Link></h2>
			            <div className="product-description">
			              <p>Zaitoon Lifestyle is strategically situated in Lahore, offering effortless access to the city's key points of interest, educational centers, healthcare amenities, and entertainment venues. This location provides a harmonious fusion of convenience and seamless connectivity.

Just a 20-minute drive away lies the Allama Iqbal International Airport, ensuring swift access for travelers.  </p>
			            </div>
			
			          </div>
			  
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/smart.png"} alt="#" /></Link>

			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Smart Living New Lahore City, Lahore</Link>
			                  </li>
			                </ul>
			              </div>

			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as Rs 17,999<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Smart Living, New Lahore City </Link></h2>
			            <div className="product-description">
			              <p>The New Lahore City Smart Living stands out as one of the monumental residential undertakings in Pakistan, ranking among the most ambitious real estate ventures. Positioned along the main Canal Bank Road and boasting LDA approval, this housing society encompasses a vast expanse of land, presenting an array of plot sizes including 3 Marla, 5 Marla, and 8 Marla.</p>
			            </div>
			         
			          </div>
			    
			        </div>
			      </div>
			      {/* ltn__product-item */}
			      <div className="col-lg-12">
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/victoria.png"} alt="#" /></Link>
			        
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
			                    <Link to="/contact"><i className="flaticon-pin" /> Victoria Heights, New Lahore City, Lahore</Link>
			                  </li>
			                </ul>
			              </div>
			            
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>As low as Rs 27,999<label>/Month</label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to="/product-details">Victoria Heights </Link></h2>
			            <div className="product-description">
			              <p>Victoria Heights in New Lahore City redefines contemporary urban living. Developed by seasoned professionals, it presents a diverse range of thoughtfully designed apartments to cater to various preferences and needs. Beyond the well-crafted living spaces, Victoria Heights boasts a plethora of amenities, ensuring a dynamic and fulfilling lifestyle for its residents. </p>
			            </div>
			            
			          </div>
			          
			        </div>
			      </div>

				  {/*  */}
			    </div>
			  </div>
			</div>

			

			

			
		   </div>
        }
}

export default ProductSliderV1