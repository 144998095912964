import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Inspiration</h6>
			          <h1 className="section-title">Inspired by Excellence: Unveiling Our Vision</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/jannat.jpg"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/service-details">Jannat at Midtown</Link></h3>
			            <p>"Jannat at Midtown by Deyaar in Dubai Production City offers elegant studios and 1-3 bedroom apartments with top-notch amenities. This five million sq. ft. development features four distinct districts, enhancing urban living. The landscaped town, Jannat, offers lush parks and serene spaces for relaxation. Modern gyms, pools, and gardens cater to an active lifestyle. With refined interiors and easy access to retail and dining, Jannat at Midtown delivers a luxurious living experience in Dubai."</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/amalia.jpg"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/service-details">Amalia by Deyaar</Link></h3>
			            <p>Amalia Residences at Al Furjan, the latest development by Deyaar Properties, offers a collection of meticulously designed 1, 2 & 3 bedroom apartments in vibrant Dubai. This mid-rise tower ensures ample lighting and elegance, promising a lifestyle of comfort and brightness. With carefully planned spacious rooms, top-tier finishes, and a host of amenities including an outdoor pool, gym, and landscaped gardens, residents experience modern urban living at its best. </p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/marcasa.jpg"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/service-details">MAR CASA By Deyaar</Link></h3>
			            <p>Mar Casa at Dubai Maritime City, a 52-storey architectural marvel by Deyaar, This development epitomizes ultra-luxury living in a historic space at Port Rashid, boasting stunning views of the cityscape, waters, and social areas. Inspired by ocean waves, this iconic infrastructure is designed to promote an active, healthy lifestyle. With nature-inspired designs and breathtaking views from every room and balcony, Mar Casa provides an elegant and affordable lifestyle with seamless brilliance.</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1