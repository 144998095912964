import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-115 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-5 align-self-center">
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl+"assets/img/service/urban.png"} alt="Image" />
					</div>
					</div>
					<div className="col-lg-7 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Where Dreams Find Their Home</h6>
						<h1 className="section-title">Elevating Urban Living: Deyaar Developers Redefining Excellence<span>.</span></h1>
						<p style={{ textAlign: 'justify' }}>Deyaar Developers, a distinguished name in the real estate arena, has been setting new benchmarks in quality and innovation for decades. With a legacy built on excellence, we take pride in crafting iconic projects that redefine urban living. Meticulous planning, contemporary design, and an unwavering commitment to excellence are the cornerstones of our approach. Every development is more than just a structure; it's a vibrant community where comfort, convenience, and luxury harmoniously coexist.</p>
					
						<p style={{ textAlign: 'justify' }}> We believe in creating spaces that inspire and elevate lives. Our projects are meticulously designed to cater to the diverse needs and aspirations of modern urban dwellers. Whether it's residential, commercial, or mixed-use developments, each endeavor reflects our dedication to delivering spaces of exceptional quality. With a portfolio spanning across prestigious locations, we continue to shape environments that leave a lasting impression and enhance the overall urban landscape</p>
						</div>
						
					</div>
					</div>
				</div>
				</div>
			</div>	
        }
}

export default AboutV5