import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-110--- pb-90">
				<div className="container">
				<div className="row justify-content-center go-top">
					
				<div className="col-lg-12 col-sm-12">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						
						</div>
						<div className="team-info">
						<h4>Important Notice:</h4><p>Dear valued visitors, the individuals listed on our official website under the "Members" section are the only recognized representatives of Deyaar Developers; any person claiming to represent Deyaar Developers without being listed is not affiliated with us, and Deyaar Developers disclaims responsibility for their actions. Thank you for your understanding and cooperation. Best regards,<br />Deyaar Developers Management </p>

						
					{/*	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/talhakhan.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Talha Khan</Link></h4>
						<h6 className="ltn__secondary-color">Chief Executive Officer</h6>
						{/*<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
	</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/kamran.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Muhammad Kamran Munir</h4>
						<h6 className="ltn__secondary-color">Managing Director</h6>
						{/*<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/nouman.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Muhammad Nouman Munir</h4>
						<h6 className="ltn__secondary-color">IT Manager</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/Sunila.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Sunila Khan</h4>
						<h6 className="ltn__secondary-color">Sales Head</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>

					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/adil.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Dr Adil Afifi</h4>
						<h6 className="ltn__secondary-color">Director Sales Gulf Region</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>

					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/talhabinghulam.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Talha Bin Ghulam</h4>
						<h6 className="ltn__secondary-color">IT Manager Gulf</h6>
						{/*<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					
					</div>

					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/husnain.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Syed Husnain Haider</h4>
						<h6 className="ltn__secondary-color">Regional Manager Gulf</h6>
						{/*<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>

					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/zied.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Zied Chabane</h4>
						<h6 className="ltn__secondary-color">Sales Manager Gulf Region</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>

					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/ZainMunir.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Zain Munir Ahmad</h4>
						<h6 className="ltn__secondary-color">Business Manager</h6>
					{/*	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>				
	
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/wasif.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Dr Wasif</h4>
						<h6 className="ltn__secondary-color">Admin Manager</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/romaila.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Adv. Romaila Shaikh</h4>
						<h6 className="ltn__secondary-color">Legal Advisor</h6>
					{/*	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/Noor.png"} alt="Image" />
						</div>
						<div className="team-info">
						<h4>Noor Ul Ain</h4>
						<h6 className="ltn__secondary-color">Assistant Sales Manager</h6>
					{/*}	<div className="ltn__social-media">
							<ul>
							<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#"><i className="fab fa-linkedin" /></a></li>
							</ul>
						</div>*/}
						</div>
					</div>
					</div>



	

				</div>
				</div>
			</div>
        }
}

export default TeamV2