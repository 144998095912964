import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__team-details-area mb-120">
                <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                    <div className="ltn__team-details-member-info text-center mb-40">
                        <div className="team-details-img">
                        <img src={publicUrl+"assets/img/team/talha.png"} alt="Team Member Image" />
                        </div>
                        <h2>Talha Khan</h2>
                        <h6 className="text-uppercase ltn__secondary-color">CEO Deyaar Developers</h6>
                        {/*<div className="ltn__social-media-3">
                        <ul>
                            <li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                            <li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                        </ul>
                        </div>*/} 
                    </div>
                    </div>
                    <div className="col-lg-8">
                    <div className="ltn__team-details-member-info-details">
                        <p>
Talha Khan stands as a stalwart figure in the realm of real estate, boasting a career spanning over two decades. As the CEO of Deyaar Developers, he has carved a niche for himself and his company in the competitive landscape of the real estate business. With a keen eye for opportunities and a commitment to excellence, Talha Khan has been instrumental in steering Deyaar Developers towards new heights of success.</p>
                        <div className="row">
                        <div className="col-lg-6">
                            <div className="ltn__team-details-member-about">
                            <ul>
                                <li><strong>Positions:</strong>CEO Deyaar Developers</li>
                                <li><strong>Experience:</strong> 20+ Years</li>
                                <li><strong>Location:</strong> Lahore</li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ltn__team-details-member-about">
                            <ul>
                                <li><strong>Email:</strong> talha@deyaardevelopers.com</li>
                                <li><strong>Fax:</strong> +92 300 0097362</li>
                                <li><strong>Phone:</strong> +92 310 6491111</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <hr />
                        <p>Throughout his extensive career, Talha Khan has forged strategic partnerships with some of the leading real estate giants in Pakistan, establishing himself as a trusted and influential player in the industry. His collaborative ventures have not only strengthened the market presence of Deyaar Developers but have also contributed significantly to the growth of the real estate sector in the region. Talha Khan's visionary leadership is underscored by the numerous successful projects he has spearheaded, including notable developments such as Zaitoon, New Lahore City, and Etihaad Town. These projects stand as testaments to his dedication to creating innovative and sustainable real estate solutions that resonate with the needs of the community.</p>
                        <div className="row ltn__custom-gutter mt-50 mb-20">
                        <div className="col-xl-4 col-sm-6 col-12 go-top">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center">
                            <div className="ltn__feature-icon">
                                <span><i className="icon-tire" /></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h4><Link to="/service">Real Estate Consultancy</Link></h4>
                                <p>Talha Khan, with 20+ years in real estate, offers strategic development insights for optimal project outcomes and investment opportunities.</p>
                                {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}

                                
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 col-12">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center active">
                            <div className="ltn__feature-icon">
                                <span><i className="icon-mechanic" /></span>
                            </div>
                            <div className="ltn__feature-info go-top">
                                <h4><Link to="/team">Partnership Facilitation</Link></h4>
                                <p>Known for successful collaborations, Talha fosters strategic partnerships that enhance market positions in Pakistan's real estate sector.</p>
                                {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}
                               
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 col-12">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center">
                            <div className="ltn__feature-icon">
                                <span><i className="icon-wheel-1" /></span>
                            </div>
                            <div className="ltn__feature-info go-top">
                                <h4><Link to="/service-details">Project Management</Link></h4>
                                <p>As Deyaar Developers' CEO, Talha ensures top-tier quality and innovation in projects like Zaitoon, New Lahore City, and Etihaad Town.</p>
                                {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}
                                
                            </div>
                            </div>
                        </div>
                        </div>
                        <p>As a seasoned professional, Talha Khan continues to shape the landscape of the real estate industry, blending his wealth of experience with a forward-thinking approach. His enduring commitment to excellence and his ability to navigate the complexities of the real estate market make him a respected figure and a driving force behind Deyaar Developers' continued success.</p>
                        <div className="ltn__form-box contact-form-box box-shadow white-bg mt-50">
                        <h4 className="title-2">Contact for any Inquiry</h4>
                        <form id="contact-form" action="mail.php" method="post">
                            <div className="row">
                            <div className="col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                <input type="text" name="name" placeholder="Enter your name" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-item input-item-email ltn__custom-icon">
                                <input type="email" name="email" placeholder="Enter email address" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-item">
                                <select className="nice-select">
                                    <option>Select Service Type</option>
                                    <option>Property Management </option>
                                  
                                    <option>Consulting Service</option>
                                    <option>Home Buying</option>
                                    <option>Home Selling</option>
                                   
                                </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-item input-item-phone ltn__custom-icon">
                                <input type="text" name="phone" placeholder="Enter phone number" />
                                </div>
                            </div>
                            </div>
                            <div className="input-item input-item-textarea ltn__custom-icon">
                            <textarea name="message" placeholder="Enter message" defaultValue={""} />
                            </div>
                            <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p>
                            <div className="btn-wrapper mt-0">
                            <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">get an free service</button>
                            </div>
                            <p className="form-messege mb-0 mt-20" />
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        }
}

export default TeamDetails