import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
						<h1 className="section-title">Our Core Services</h1>
					</div>
					</div>
				</div>
				<div className="row  justify-content-center">
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Property Management</Link></h3>
						<p style={{ textAlign: 'justify' }}>Enhance your real estate investments with Deyaar Developers' Property Management Service. We specialize in optimizing property value and returns through strategic sales and purchases. Our dedicated team focuses on maximizing your profits, providing a seamless experience for property owners seeking lucrative opportunities.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-excavator" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Construction Service</Link></h3>
						<p style={{ textAlign: 'justify' }}>Discover excellence in construction with Deyaar Developers. Our Construction Service goes beyond bricks and mortar, delivering meticulously planned projects with precision. From concept to completion, we ensure quality craftsmanship, on-time delivery, and cost-effectiveness. Trust us to bring your visions to life, creating spaces that stand the test of time.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-operator" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Consultation Service</Link></h3>
						<p style={{ textAlign: 'justify' }}>Elevate your real estate ventures with Deyaar Developers' Consulting Service. Our experts offer invaluable insights, guiding you through the intricacies of the property market. Whether it's investment strategies, market trends, or valuation advice, we're here to empower your decisions. Let our expertise be your compass in the dynamic world of real estate.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-1" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Real Estate Development</Link></h3>
						<p style={{ textAlign: 'justify' }}>Unleash the potential of your land with Deyaar Developers' Real Estate Development service. We bring visionary projects to life, creating communities that thrive. Our expertise spans from concept creation to execution, ensuring every development leaves a lasting mark on the landscape.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-3" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Investment Advisory</Link></h3>
						<p style={{ textAlign: 'justify' }}>Navigate the real estate market with confidence through Deyaar Developers' Investment Advisory service. Our seasoned analysts provide data-driven insights and market intelligence, empowering you to make informed investment decisions. Trust us to identify opportunities that align with your financial goals.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-official-documents" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Property Valuation</Link></h3>
						<p style={{ textAlign: 'justify' }}>Gain clarity on your real estate assets with Deyaar Developers' Property Valuation service. Our certified appraisers conduct thorough assessments, providing accurate and reliable valuations. Whether for buying, selling, or financial planning, trust us for precise and transparent property evaluations.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ServiceV5